import Alerts from "../messaging/alerts";

export default function PrimaryLayout({ className, children }) {
  return (
    <div className={`h-screen w-screen ${className}`}>
      <div className="h-full w-full">{children}</div>
      <Alerts />
    </div>
  );
}
