import { supabase } from "./supabase";

export async function clientSideFetch(url, options = {}) {
  const { data, error } = await supabase.auth.getSession();
  if (!error) {
    const token = data.session.access_token;
    const result = await fetch(url, {
      ...options,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        ...options?.body,
      }),
    });
    const json = await result.json();
    return json;
  }
}
