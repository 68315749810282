import PrimaryLayout from "@/components/layout/primaryLayout";
import Router from "@/components/navigation/router";
import { MessagingContextWrapper } from "@/context/messagingContext";
import { SupabaseContextWrapper } from "@/context/supabaseContext";
import "@/styles/globals.css";
import "prismjs/themes/prism-tomorrow.css";
import useExceptra from "@exceptra/exceptra-next";
import { Inter, Inconsolata } from "next/font/google";

const inter = Inter({
  subsets: ["latin"],
  variable: "--inter-font",
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
});

const inconsolata = Inconsolata({
  subsets: ["latin"],
  variable: "--inconsolata-font",
  weight: ["200", "300", "400", "500", "600", "700", "800", "900"],
});

export default function App({ Component, pageProps }) {
  useExceptra({
    disableWhen: process.env.NEXT_PUBLIC_ENVIRONMENT !== "DEVELOPMENT",
    apiKey: "f19a4d567b20308b20ee53773a7ad31b316dc51512339ad58c12a7e5e5fc172f",
    group: "server",
    tags: ["nextjs", "website"],
    metadata: {
      useragent: "Safari",
    },
  });
  return (
    <SupabaseContextWrapper>
      <MessagingContextWrapper>
        <Router>
          <PrimaryLayout
            className={`${inter.variable} ${inconsolata.variable}`}
          >
            <Component {...pageProps} />
          </PrimaryLayout>
        </Router>
      </MessagingContextWrapper>
    </SupabaseContextWrapper>
  );
}
