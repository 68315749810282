import { useSupabaseContext } from "@/context/supabaseContext";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function Router({ children }) {
  const router = useRouter();
  const supabaseContext = useSupabaseContext();

  useEffect(() => {
    if (!supabaseContext.user?.id) {
      if (router.pathname.includes("/app")) {
        router.push("/login");
      }
    } else {
      if (supabaseContext.profile && !supabaseContext?.profile?.name) {
        router.push("/onboarding");
      } else if (router.pathname === "/onboarding") {
        router.push("/app");
      } else if (router.pathname === "/login") {
        router.push("/app");
      }
    }
  }, [router.pathname, supabaseContext.user, supabaseContext.profile]);

  return children;
}
