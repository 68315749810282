import { clientSideFetch } from "@/helpers/fetch";
import { supabase } from "@/helpers/supabase";
import { useContext, createContext, useEffect, useState } from "react";

export const SupabaseContext = createContext();

export function SupabaseContextWrapper({ children }) {
  // State
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  const [sources, setSources] = useState();
  const [cache, setCache] = useState([]);

  // Authentication Listener (Client Side)
  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session?.user?.id) {
        setUser(session.user);
        fetchProfile(false);
        fetchSources();
      } else {
        setUser(null);
        setProfile();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Fetch User Profile
  const fetchProfile = async (refetch = true) => {
    if (refetch || !profile) {
      const json = await clientSideFetch("/api/internal/fetch_profile");
      if (json?.status === "success") {
        setProfile(json.message);
      }
    } else if (profile) {
      return profile;
    }
  };

  // Update and Refetch User Profile
  const updateProfile = async (profileData) => {
    await clientSideFetch("/api/internal/update_profile", {
      body: {
        profileData: profileData,
      },
    });
    await fetchProfile();
    return;
  };

  // Fetch logged sources
  const fetchSources = async () => {
    if (!sources) {
      const json = await clientSideFetch("/api/internal/fetch_sources");
      if (json?.status === "success") {
        setSources(json.data);
      }
    } else if (sources) {
      return sources;
    }
  };

  // Fetch events
  const fetchEvents = async (refetch = false, pageSize = 10, filters = {}) => {
    const query = `${JSON.stringify(filters)}`;
    const offset = cache?.[query]?.offset || 0;
    if (cache?.[query]?.end && !refetch) return;
    const json = await clientSideFetch("/api/internal/fetch_events", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        offset: refetch ? 0 : offset,
        pageSize: pageSize,
        filters,
      },
    });
    setCache((prevCache) => {
      const currentItems = refetch ? [] : prevCache?.[query]?.items || [];
      const newEvents = [...currentItems, ...json.data];
      return {
        ...prevCache,
        [query]: {
          items: newEvents,
          offset: newEvents.length,
          end: json.data.length < pageSize ? true : false,
        },
      };
    });
  };

  const sharedState = {
    user,
    profile,
    fetchProfile,
    updateProfile,
    sources,
    cache,
    fetchEvents,
  };

  return (
    <SupabaseContext.Provider value={sharedState}>
      {children}
    </SupabaseContext.Provider>
  );
}

export function useSupabaseContext() {
  return useContext(SupabaseContext);
}
