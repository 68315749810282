import { useMessagingContext } from "@/context/messagingContext";
import { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function Alerts() {
  const messagingContext = useMessagingContext();
  return (
    <div className="absolute bottom-3 right-6 flex flex-col-reverse">
      {messagingContext.messages?.map((item, key) => {
        return (
          <AlertBox
            key={key}
            item={item}
            index={key}
            onClick={() => messagingContext.removeMessage(key)}
          />
        );
      })}
    </div>
  );
}

function AlertBox({ item, index, onClick }) {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setClosed(true);
    }, 250);
  }, []);

  const close = () => {
    setClosed(false);
    setTimeout(() => {
      onClick();
    }, 250);
  };

  return (
    <div
      style={{
        opacity: closed ? 1 : 0,
        transform: `translateY(${closed ? "-10px" : 0})`,
        zIndex: index,
        transition: "0.25s all",
        height: 40,
      }}
      className={`mt-2 ${
        item.status === "error" ? "bg-red-500" : "bg-green-500"
      } w-72 flex items-center justify-between px-4 text-white text-xs text-console rounded-lg`}
    >
      <p>{item.text}</p>
      <XMarkIcon
        className="h-4 w-4 hover:text-black transition"
        onClick={onClick}
      />
    </div>
  );
}
