import { useContext, createContext, useState } from "react";

export const MessagingContext = createContext();

export function MessagingContextWrapper({ children }) {
  // State
  const [messages, setMessages] = useState([]);

  const addMessage = (message) => {
    setMessages([...messages, message]);
  };

  const removeMessage = (index) => {
    const newMessages = [
      ...messages.slice(0, index),
      ...messages.slice(index + 1),
    ];
    setMessages(newMessages);
  };

  const sharedState = {
    messages,
    addMessage,
    removeMessage,
  };

  return (
    <MessagingContext.Provider value={sharedState}>
      {children}
    </MessagingContext.Provider>
  );
}

export function useMessagingContext() {
  return useContext(MessagingContext);
}
